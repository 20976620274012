import deepmerge from 'deepmerge';
import { ENVIRONMENT_GLOBAL } from '../global_env';

export const environment = deepmerge(ENVIRONMENT_GLOBAL, {
  envName: 'test',
  production: true,
  redirect: {
    host: 'https://test-oms.meinhplus.de'
  },
  auth_L1: {
    client_id: 'oms-tool',
    scope: 'phone email profile openid',
    endpoint: {
      host: 'https://test-account.meinhplus.de',
      paths: {
        prefix: '/auth/realms/hekatron-tuer-tor'
      }
    }
  },
  backend: {
    scope: 'oms',
    client_id: 'oms-webapp-aS1hG5kPl7Mn0ZxC',
    endpoint: {
      host: 'https://api.test-oms.meinhplus.de'
    }
  },
  files: {
    paths: {
      host: 'https://test-administration.meinhplus.de' //TODO check
    }
  },
  links: {
    applications: {
      mh2: 'https://test.meinhplus.de',
      hcp: 'https://test-dashboard.meinhplus.de',
      l2: 'https://test-administration.meinhplus.de'
    }
  }
});
